@import "../../../style/media-query";


.subscriptionManagerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subscriptionStatus {
  background-color: #F9FCE7;
  padding: 30px 0;
  align-items: center;
  justify-items: center;
  min-height: fit-content;
  flex-shrink: 0;

  .status {
    font-size: 16px;
    line-height: 20px;
    color: var(--charcoal);
    text-align: center;

    &.active {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.products {
  display: flex;
  max-width: 100%;
  align-self: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
  min-width: 0;
  flex-direction: column;
  min-height: max-content;
  flex: 1 0 auto;
  width: 100%;
  height: initial;
  justify-content: flex-start;
  padding-top: 35px;
  padding-bottom: 5px;

  @media screen and (max-width: #{$phone-landscape-width}) {
    padding-bottom: 20px;
  }
}
