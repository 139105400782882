.discountTag {
  position: absolute;
  top: -20px;
  right: -20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .15);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: white;
  z-index: 3 !important;

  &.mobile {
    left: -18px;
    right: unset;
    top: -10px;
    width: 44px;
    height: 44px;

    span:first-child {
      font-size: 12px;
    }
  }
}

.planCard {
  position: relative;
  z-index: 0;
}

.cardShadow {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, .15) !important;
}
