$height: 56px;

.mobileHeader {
  background-color: var(--primaryGreen);
  display: flex;
  flex-direction: row;
  color: white;
  position: relative;
  height: $height;
  min-height: $height;

  h1 {
    font-size: 16px;
    margin: auto 0;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 10px;

    p,
    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      margin: 0;
    }

    span {
      font-size: 12px;
      line-height: 14px;
      font-weight: 300;
      margin-top: 2px;
    }
  }
}

.backButton {
  background-color: transparent;
  border: 0;
  color: white;
  font-size: 22px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
