.mobileTabs {
  box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
  position: relative;
  z-index: 2;

  ul {
    display: flex;
    list-style: none;
    flex-direction: revert;
    justify-content: space-between;
    background: white;
    padding: 0;
    margin: 0;

    li {
      width: 100%;
      height: var(--mobileFooterHeight);
      position: relative;

      a {
        color: var(--primaryGreen);
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 24px;
        padding-bottom: 0;

        &.active {
          background-color: var(--primaryGreen);
          color: white;
        }
      }
    }
  }
}

.badge {
  background-color: var(--textErrorRed);
  top: 10px;
  left: 60%;
}
