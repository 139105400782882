.bellNotification {
  display: flex;
  height: 100%;
  align-items: center;

  button {
    border: 0;
    background: none;
    position: relative;
    cursor: pointer;
    padding: 0;
    color: var(--grey);
    font-size: 24px;

    &:hover {
      color: var(--charcoal);
    }
  }
}

div.bellDropContainer {
  max-height: unquote("min(600px, 80vh)") !important;
  max-width: unquote("min(355px, calc(100vw - 20px))");
  border-radius: 5px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, .15);
  overflow: hidden;
  background: white;
  min-width: fit-content;

  .wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .top {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    padding: 0 2px 0 14px;

    h4 {
      margin: 0;
    }

    svg {
      font-size: 15px;
    }
  }

  .label {
    font-size: 13px;
    line-height: 18px;
    color: var(--grey);
    padding-left: 14px;
  }

  .list {
    margin-bottom: 10px;
  }

  .noContent {
    display: flex;
    min-height: 50px;
    justify-content: center;
    align-items: center;
    min-width: unquote("min(355px, calc(100vw - 20px))");
  }
}

.notification {
  display: flex;
  height: 75px;
  cursor: pointer;

  &:hover {
    background-color: #F8F8F8;
  }

  &:active {
    color: white;
    background-color: #376CDE;

    .middle {

      p,
      span {
        color: white;
      }
    }
  }

  &.unread {
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background-color: var(--blueDark);
      border-radius: 50%;
      position: absolute;
      left: 10px;
      top: calc(50% - 3px);
    }
  }

  .left {
    display: flex;
    width: fit-content;
    align-items: center;
    padding: 0 12px 0 22px;

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }

  .middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    p {
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      color: var(--charcoal);
      margin: 0;
    }

    span {
      color: var(--charcoalLight);
      font-size: 10px;
      line-height: 16px;
      padding-right: 22px;
    }
  }
}
