.preflight,
.preflight:after,
.preflight:before {
  border: 0 solid #ebebeb;
  box-sizing: border-box;
}

.preflight:after,
.preflight:before {
  --tw-content: "";
}

.preflight-html {
  -webkit-text-size-adjust: 100%;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-variation-settings: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}

.preflight-body {
  line-height: inherit;
  margin: 0;
}

.preflight-hr {
  border-top-width: 1px;
  color: inherit;
  height: 0;
}

abbr:where([title].preflight) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

.preflight-h1,
.preflight-h2,
.preflight-h3,
.preflight-h4,
.preflight-h5,
.preflight-h6 {
  font-size: inherit;
  font-weight: inherit;
}

.preflight-a {
  color: inherit;
  text-decoration: inherit;
}

.preflight-b,
.preflight-strong {
  font-weight: bolder;
}

.preflight-code,
.preflight-kbd,
.preflight-pre,
.preflight-samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

.preflight-small {
  font-size: 80%;
}

.preflight-sub,
.preflight-sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.preflight-sub {
  bottom: -.25em;
}

.preflight-sup {
  top: -.5em;
}

.preflight-table {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

.preflight-button,
.preflight-input,
.preflight-optgroup,
.preflight-select,
.preflight-textarea {
  font-feature-settings: inherit;
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-variation-settings: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

.preflight-button,
.preflight-select {
  text-transform: none;
}

/* [type=button].preflight,
[type=reset].preflight,
[type=submit].preflight, */
.preflight-button {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

:where(.preflight):-moz-focusring {
  outline: auto;
}

:where(.preflight):-moz-ui-invalid {
  box-shadow: none;
}

.preflight-progress {
  vertical-align: baseline;
}

:where(.preflight)::-webkit-inner-spin-button,
:where(.preflight)::-webkit-outer-spin-button {
  height: auto;
}

/* [type=search] */
.preflight-search {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

:where(.preflight)::-webkit-search-decoration {
  -webkit-appearance: none;
}

:where(.preflight)::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.preflight-summary {
  display: list-item;
}

.preflight-blockquote,
.preflight-dd,
.preflight-dl,
.preflight-fieldset,
.preflight-figure,
.preflight-h1,
.preflight-h2,
.preflight-h3,
.preflight-h4,
.preflight-h5,
.preflight-h6,
.preflight-hr,
.preflight-p,
.preflight-pre {
  margin: 0;
}

.preflight-fieldset,
.preflight-legend {
  padding: 0;
}

.preflight-menu,
.preflight-ol,
.preflight-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.preflight-dialog {
  padding: 0;
}

.preflight-textarea {
  resize: vertical;
}

.preflight-input::-moz-placeholder,
.preflight-textarea::-moz-placeholder {
  color: #9ca3af;
  opacity: 1;
}

.preflight-input::placeholder,
.preflight-textarea::placeholder {
  color: #9ca3af;
  opacity: 1;
}

/* [role=button].preflight, */
.preflight-button {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

.preflight-audio,
.preflight-canvas,
.preflight-embed,
.preflight-iframe,
.preflight-img,
.preflight-object,
.preflight-svg,
.preflight-video {
  display: block;
  vertical-align: middle;
}

.preflight-img,
.preflight-video {
  height: auto;
  max-width: 100%;
}

[hidden] {
  display: none;
}

input.preflight[type="number"]::-webkit-inner-spin-button,
input.preflight[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.preflight[type="number"] {
  -moz-appearance: textfield;
}
