.appLoader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5vw;
  z-index: 10;
  font-weight: 500;
}
