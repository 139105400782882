.mobileLayout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.mobileLayoutContent {
  display: flex;
  flex-grow: 1;
  overflow: auto;
  position: relative;

  // this will hide the floating header from the components we are temporarily using
  header[class*="header"] {
    display: none;
  }

  :global {

    .pageWrapper {
      padding: 0;
    }
  }
}
