.menuContainer {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  user-select: none;
}

.drop {
  margin-top: 16px;
  margin-right: 8px;

  .wrapper {
    padding-bottom: 15px;
    cursor: default;

    > div:not(.logo) {
      cursor: pointer;

      &:hover {
        background: #EBEBEB;
      }
    }
  }

  h4 {
    margin: 12px 0 4px 16px;
    pointer-events: none;
    cursor: default;
  }

  hr {
    background: black;
    width: calc(100% - 16px);
    height: 2px;
    margin: auto;
  }

  svg {
    width: 25px !important;

    + div {
      margin-left: 10px;
    }
  }
}
