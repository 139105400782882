@import "../../../style/media-query";

.searchWrapper {
  border-radius: 50px;

  input::-webkit-input-placeholder,
  input::-moz-placeholder,
  input:-ms-input-placeholder,
  input:-moz-placeholder,
  input::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--grey);
  }

  input {
    font-weight: normal;
  }
}

.suggestion {
  text-decoration: none;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 500;
}

.img {
  width: 30px;
  height: 30px;

  &.liveTalk {
    border: 3px solid var(--onlineGreen);
  }

  &.online {
    border: 3px solid var(--onlineGreen);
  }

  &.offline {
    border: 3px solid var(--offlineGrey);
    opacity: .6;
  }
}

.lock {
  width: 20px;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--oliveLight);
  font-size: 10px;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.offline {
    color: white;
    background: #3B499C;
  }
}

.searchClickIndicator {
  pointer-events: all;

  div,
  svg {
    z-index: 1;
    pointer-events: all;
  }
}

:global {

  ol[class^="StyledTextInput__StyledSuggestions"] li {
    display: flex;

    img {
      height: 30px;
    }

    a>div {
      width: 100%;
      padding: 12px;
    }
  }
}

.habitatStatusMarginRight {
  margin-right: 6px;
}
