@import "../../../../style/media-query.scss";

div.cancelReasonContainer {
  display: flex;
  flex-direction: column;
  width: 620px;
  background: white;
  color: var(--charcoal);
  border-radius: 12px;

  .close {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .container {
    position: relative;
    overflow-y: auto;
    padding: 20px 40px 40px 40px;

    p:first-child {
      margin-top: 0;
    }
  }

  textarea {
    border-radius: 12px;
    margin-top: 20px;
    width: 100%;
    padding: 10px 12px;
    resize: none;
    font-size: 16px;
    font-weight: 300;
    line-height: 16.5px;
    border: 1px solid #000;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, .25), 0px 4px 15px 0px rgba(0, 0, 0, .10) inset;
  }

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    .backButton {
      display: flex;
      align-items: center;
      color: #8C8C8C;
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      padding: 0;

      svg {
        margin-right: 8px;
      }
    }


    .nextButton {
      align-items: center;
    }
  }

  .radioButtons {
    display: flex;
    flex-direction: column;
    gap: 5px;

    [class*="StyledRadioButtonContainer"] {
      display: flex;
      align-items: center;

      &:hover {
        color: black;

        [class*="StyledRadioButtonBox"] {
          border: 1px solid black;
        }
      }

      [class*="StyledRadioButtonBox"] {
        border: 1px solid var(--charcoal);
        margin-right: 10px;
      }

      [tabindex="0"]+div svg {
        fill: var(--primaryGreen);
      }
    }
  }
}

.offerContainer {
  padding: 20px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.offerTitle {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  color: var(--primaryGreen);
  margin-bottom: 16px;
  margin-top: 26px;
  line-height: 100%;
}

.offerDescription {
  align-self: stretch;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 24px;
  white-space: pre-line;
  text-align: left;
}

.offerButtonsContainer {
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 12px;
}

.noThanksButton,
.claimOfferButton {
  flex: 1;
  width: 100%;
}

.successMessage {
  color: #4CAF50;
  margin-bottom: 16px;
  text-align: left;
  font-weight: 500;
}

.closeButton {
  width: 100%;
  max-width: 200px;
}

.errorMessage {
  color: #F44336;
  margin-bottom: 16px;
  text-align: center;
}

@media screen and (max-width: #{$phone-landscape-width}) {

  div.cancelReasonContainer {
    width: 90%;

    .container {
      padding: 20px;
    }
  }

  .offerContainer {
    padding: 20px;
  }

  .offerTitle {
    font-size: 26px;
    margin-bottom: 12px;
  }

  .offerDescription {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 300;
  }

  .offerButtonsContainer {
    flex-direction: column;
    gap: 12px;
  }

  .noThanksButton,
  .claimOfferButton {
    max-width: 100%;
  }
}

.pauseDurationContainer {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pauseDurationText {
  font-size: 14px;
  color: #4a4a4a;
  margin-right: 16px;
}

.quantitySelector {
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 20px;
  overflow: hidden;

  button {
    background: transparent;
    border: 0;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color .3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #f0f0f0;
    }

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .quantityValue {
    padding: 0 6px;
    font-size: 16px;
    min-width: 60px;
    text-align: center;
    white-space: nowrap;
    display: flex;
    align-items: baseline;
    justify-content: center;
  }

  .quantityUnit {
    font-size: 12px;
    margin-left: 6px;
  }
}

.durationButton {
  padding: 6px;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
}
