.menuContainer {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 14px 20px 14px 8px;
}

.signupButton {
  height: 58px;
}

.menuDrop > * {
  margin-top: 10px;
  min-height: fit-content;
}

.burgerBtn {
  display: flex !important;
  justify-content: center;
  padding: 0;
  border: 0;
  background: transparent;
  font-size: inherit;
}
