.layer {
  border-radius: 16px !important;
}

.container {
  width: 327px;
  height: 256px;

}

.contentWrapper {
  padding: 0 24px;
}


.changeLogContainer {
  overflow: auto;
  margin-top: 10px;

}
