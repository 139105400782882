.modalStyle {
  border-radius: 12px !important;
}

.close {
  position: absolute;
  right: 8px;
  top: 8px;
}

.cancelSubscriptionContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 400px;
  padding: 45px 50px;
  position: relative;
  text-align: center;

  h4 {
    margin: 0;
  }

  .text {
    margin: 10px 0 10px 0;
    display: flex;
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;

    button {
      min-width: calc(50% - 10px);

      &:first-child:last-child {
        margin: auto;
      }
    }
  }
}
