.body {
  position: absolute;
  height: calc(100vh - var(--headerHeight));
  width: 100vw;
  background-color: transparent;


  .content {
    position: absolute;
    height: 60vh;
    width: 600px;
    max-width: calc(100vw - 30px);
    max-height: calc((100 * var(--vh)) - 30px);
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, .25);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    box-shadow: 0 0 10px 0 #545454;

    .header {
      width: 100%;
      display: flex;
      font-size: 20px;
      font-weight: bold;
      padding: 10px 16px;
      align-items: center;
      justify-content: space-between;
    }

    .widget {
      height: 100%;
      width: 100%;
    }
  }

  iframe {
    border-radius: 0 0 8px 8px !important;
  }
}
