.statusTag {

  background-color: var(--pink);
  color: white;
  display: inline-flex;
  height: 24px;
  border-radius: 12px;
  padding: 9px;
  min-width: 68px;
  align-items: center;
  justify-content: center;

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }

  &.online {
    background: var(--onlineGreen);
  }

  &.liveTalk {
    background: var(--onlineGreen);
  }

  &.offline {
    background: var(--offlineGrey);
  }
}
