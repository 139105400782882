.searchButton {
  background-color: transparent;
  border: 0;
  color: white;
  font-size: 22px;
  padding: 0 20px;
  margin-left: auto;
}

.searchContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;

  > div {
    width: 100%;
    padding: 0 18px;
    align-items: center;
    justify-content: center;
    display: flex;
    background: var(--primaryGreen);
  }

  .close {
    background-color: transparent;
    border: 0;
    color: black;
    font-size: 22px;
    padding: 0 30px 0 10px;
    margin-left: auto;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

div.searchBar {
  width: 100%;
  border-radius: 10px;
  border: 2px solid #F0F2E1;
  background: white;
  min-height: 48px;

  > div:nth-child(2) {
    display: none;
  }
}
