.menuButton {
  background-color: transparent;
  border: 0;
  color: white;
  font-size: 22px;
  padding: 0 20px;
}

div.menuSideBar {
  background-color: white;
  max-height: 100%;
  min-width: 327px;
  padding: 24px 0 20px 20px;

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .logo {
      width: fit-content;
      display: flex;
    }

    .close {
      background: none;
      border: 0;
      font-size: 20px;
      padding: 4px 10px;
      position: absolute;
      right: 10px;
      top: 20px;
    }
  }
}

.scrollContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 20px 0;

  .scroll {
    height: 100%;
    overflow: auto;

    h4 {
      color: #212121;
      font-weight: 700;
      margin: 50px 0 0 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.item {
  display: flex;
  flex-direction: row;
  color: var(--descriptionGrey);
  font-size: 16px;
  margin: 20px 0;
}

.social {
  display: flex;
  flex-direction: row;
  margin-top: auto;

  a {
    margin-right: 35px;
    font-size: 28px;
    color: #212121;

    &:last-child {
      margin-right: 0;
    }
  }
}

button.upgrade {
  margin: 20px 0;
  max-width: 270px;
}
