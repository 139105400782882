.animalIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 4px;

  img {
    width: 100%;
    height: 100%;
  }
}
