$animationDuration: 1.5s;
$delay: .2s;
$baseDelay: 0s;
$leftDelay: $delay;
$middleLeftDelay: $leftDelay + $delay;
$middleRightDelay: $middleLeftDelay + $delay;
$rightDelay: $middleRightDelay + $delay;

.absolute {
  position: absolute;
  background-color: white;
  z-index: 3;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.animation {
  fill: transparent;

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  path {
    animation: fade $animationDuration linear alternate infinite;
  }
}

@keyframes fade {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
