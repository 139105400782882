.close {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: unset;
  border: 0;
  font-size: 32px;
  padding: 0;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  &.grey {
    background: rgba(255, 255, 255, .75);
    color: var(--grey);
    font-size: 22px;
  }

  &.green {
    background: rgba(9, 71, 74, .9);
    color: white;
    font-size: 22px;
  }

  &.plain {
    color: black;
    font-size: 22px;
  }
}
