@import "tailwindcss/base";

@import "./tailwind/preflight.css";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

:root {
  //Primary
  --hunterGreenDark: #081B0C; // Hunter Green/1
  --hunterGreenMediumDark: #122717; // Hunter Green/2
  --hunterGreen: #24412B; // Hunter Green/3
  --hunterGreenLight: #92C59E; // Hunter Green/5

  // new colors theme update
  --primaryGreen: #265832; // main green
  --secondaryGreen: #458254; // main green hover
  --primaryYellow: #FFCE1F; // main yellow
  --secondaryYellow: #FFDF6C; // main yellow hover
  --beigeLight: #F0F2E1; // main beige

  --blueDark: #376CDE; // Blue/1
  --blueMediumDark: #507EDE; // Blue/2
  --blue: #7396DF; // Blue/3
  --blueMediumLight: #9BB7F1; // Blue/4
  --blueLight: #C5D8FF; // Blue/5
  --logoBlue: #5B8EF6;

  // Accent
  --purple: #3B499C; // Purple/1
  --purpleMedium: #505DAA; //Purple/2
  --purpleLight: #C7CEF6; //Purple/4

  --moss: #52662D; // Moss/1
  --mossMedium: #90AA61; // Moss/2
  --mossLight: #CBDFA6; // Moss/3

  --olive: #8B9045; // Olive/1
  --oliveMedium: #CDD565; // Olive/2
  --oliveLight: #F8FEA4; // Olive/3
  --oliveLighter: #ECF389; // Olive/4

  --lavender: #71475D; // Lavendar/1
  --lavenderMedium: #B4849D; // Lavendar/2
  --lavenderLight: #FFDCEE; // Lavendar/3

  --coral: #C56622; // Coral/1
  --coralMedium: #F18C43; // Coral/2
  --coralLight: #FFB682; // Coral/3

  // System
  --red: #BF3D3F; // Red/5
  --textErrorRed: #FF5247;
  --pink: #DC3D7D; // Pink

  // Grey scale
  --charcoal: #2E2D2D; // Charcoal/1
  --charcoalLight: #535353; // Charcoal/2
  --grey: #757575; // Charcoal/3
  --descriptionGrey: #72777A; // description grey
  --mediumGrey: #CDCDCD; // Charcoal/4
  --lightGrey: #EBEBEB; // Charcoal/5
  --white: #FFFFFF; // Light Grey

  // Camera/Habitat Status
  --onlineGreen: #24A148; // online indicator
  --offlineGrey: #6F6F6F; // offline indicator


  --headerHeight: 58px;
  --maxWidth: 1750px;
  --mobileFooterHeight: 56px;
  --lavenderGradient: linear-gradient(180deg, var(--lavenderLight) 62.5%, #FEDFC9 100%);
  --landingBackground: linear-gradient(180.03deg, #FF9901 .03%, #FF979A 21.12%, #FF93EE 41.71%, #8F9AFF 73.61%, #6686F9 98.83%);
  --fontFamily: "Helvetica Neue", "zoolifeFont", sans-serif;
}

html,
body {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ebebeb;
}

* {
  font-family: var(--fontFamily);
  box-sizing: border-box;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3 {
  font-weight: 700 !important;
}

a:where(:not(.preflight)) {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-decoration-line: underline;
  color: black;
  cursor: pointer;

  @media (hover: hover) {

    &:hover:not(:disabled) {
      color: var(--charcoal);
    }
  }

  &.small {
    font-size: 13px;
    line-height: 13px;
  }

  &:active {
    opacity: .8;
  }

  &:disabled {
    color: var(--mediumGrey);
  }
}

@keyframes shimmerAnimation {

  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

.shimmer {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmerAnimation;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(270deg, #EBEBEB 0%, #CDCDCD 100%);
  border-radius: 100px;
  background-size: 1200px 100%;
  color: transparent !important;
  border: transparent !important;
  pointer-events: none;

  :disabled:hover {
    color: transparent;
  }

}

.simpleSelect {
  position: relative;

  select {
    background: transparent;
    border: 1px solid rgba(0, 0, 0, .33);
    border-radius: 4px;
    font-weight: 600;
    padding: 11px;
    padding-right: 24px;
    appearance: none;
    width: 100%;
    color: var(--charcoalLight)
  }

  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

/*
  Style that looks like grommet input
  This is used mostly for number input since grommet doesn't have that
*/
.simpleInput {
  border: 1px solid rgba(0, 0, 0, .33);
  border-radius: 4px;
  padding: 11px 6px 11px 11px;
}

.customScrollBar {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: white transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 20px;
    border: 2px solid var(--primaryGreen);
  }

  &.grey {
    scrollbar-color: var(--grey) transparent;
  }

  &.grey::-webkit-scrollbar-thumb {
    background-color: var(--grey);
    border: 2px solid transparent;
  }

  &.light {
    scrollbar-color: #E7E7E7 transparent;
  }

  &.light::-webkit-scrollbar-thumb {
    background-color: #E7E7E7;
    border: 2px solid var(--white);
  }
}

.customCheckbox {

  &.error {

    >label>div>div {
      border-color: var(--red);
    }
  }


  >label {
    outline: none !important;
    border-radius: 5px;

    div>div {
      background-color: #EBEBEB;
      border-color: #EBEBEB;
    }

    &:hover {

      div>div {
        border-color: var(--secondaryGreen) !important;
        background-color: white;

      }
    }

    &[checked="true"] {

      div>div {
        background: var(--secondaryGreen) !important;
        border-color: var(--secondaryGreen);

        svg {
          stroke: white !important;
          color: white !important;
        }
      }
    }
  }
}

.full-height {
  min-height: 100%;
}

div.calculated-full-height {
  // calculated height meant to avoid displaying under the mobile browser controls
  // this aims to have more consistent layout across devices since browsers handles it differently
  // sometimes calculated number have extra small fractions that will show un-needed scroll
  height: unquote("min(calc(var(--vh, 1vh) * 100), 100vh)");
}

.fullscreen .pageWrapper {
  padding-top: 0;
  background: black;
}


.pageWrapper {
  padding-top: var(--headerHeight);
}

@font-face {
  font-family: "zoolifeFont";
  font-style: normal;
  font-weight: 100;
  src: url("/assets/fonts/HelveticaNeueLight.woff") format("woff")
}

@font-face {
  font-family: "zoolifeFont";
  font-style: normal;
  font-weight: 200;
  src: url("/assets/fonts/HelveticaNeueLight.woff") format("woff")
}

@font-face {
  font-family: "zoolifeFont";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/HelveticaNeueMedium.woff") format("woff")
}

@font-face {
  font-family: "zoolifeFont";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/HelveticaNeueMedium.woff") format("woff")
}

@font-face {
  font-family: "zoolifeFont";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/HelveticaNeueMedium.woff") format("woff")
}

@font-face {
  font-family: "zoolifeFont";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/HelveticaNeueMedium.woff") format("woff")
}

@font-face {
  font-family: "zoolifeFont";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/HelveticaNeueBold.woff") format("woff")
}

@font-face {
  font-family: "zoolifeFont";
  font-style: normal;
  font-weight: 800;
  src: url("/assets/fonts/HelveticaNeueBold.woff") format("woff")
}

@font-face {
  font-family: "zoolifeFont";
  font-style: normal;
  font-weight: 900;
  src: url("/assets/fonts/HelveticaNeueBold.woff") format("woff")
}
